import { parseSelector } from '@/utils';

function handleNewLooseItems({ commit, getters, rootGetters, dispatch }, items) {
  const pageVersion = rootGetters['pageVersion'];
  if (getters['getLooseVersion'] < pageVersion) {
    dispatch('clearLooseItems');
  }

  items.forEach(item => commit('UPSERT_LOOSE_ITEM', item));

  commit('SET_LOOSE_VERSION', pageVersion);
}
function handleNewItems({ commit, getters, rootGetters, dispatch }, { items, total, skipSave }) {
  if (!skipSave) {
    const pageVersion = rootGetters['pageVersion'];
    if (getters['getVersion'] < pageVersion) {
      dispatch('clearItems');
    }
    items.forEach(item => commit('UPSERT_ITEM', item));
    commit('SET_TOTAL_ITEM_COUNT', total);
    commit('SET_VERSION', pageVersion);
  }
}
function handleNewPaginedItems({ commit }, { items, total, skipSave }) {
  if (!skipSave) {
    items.forEach(item => commit('UPSERT_PAGINATED_ITEM', item));
    commit('SET_TOTAL_ITEM_COUNT', total);
  }
}

const baseActions = {
  clearItems({ commit }) {
    commit('CLEAR_ITEMS');
  },
  clearLooseItems({ commit }) {
    commit('CLEAR_LOOSE_ITEMS');
  },
  deleteItem({ commit, dispatch, state }, item) {
    return dispatch('delete', item).then(() => {
      commit('DELETE_ITEM', item);
      commit('DELETE_PAGINATED_ITEM', item);
      commit('SET_TOTAL_ITEM_COUNT', state.totalItemsCount - 1);
    });
  },
  getItem({ commit, dispatch }, params) {
    commit('SET_LOADING', true);
    const skipSave = params.options && params.options.skipSave;
    const identifier = {};
    let fetchAppend = '';
    fetchAppend = parseSelector(params.selector, identifier);

    return dispatch('fetchOne' + fetchAppend, { ...identifier, params })
      .then(response => {
        const dto = response.data;
        return dispatch('transformDto', dto).then(item => {
          commit('UPSERT_LOOSE_ITEM', item);
          if (!skipSave) {
            commit('UPSERT_ITEM', item);
          }
          commit('SET_LOADING', false);
          return item;
        });
      })
      .catch(err => {
        commit('SET_LOADING', false);
        return Promise.reject(err);
      });
  },
  getItems(store, params) {
    const { commit, dispatch } = store;
    commit('SET_LOADING', true);
    return dispatch('fetchList', params)
      .then(async response => {
        const skipSave = params && params.options && params.options.skipSave;

        const items = [];
        for (const dto of response.data.items) {
          const item = await dispatch('transformDto', dto);
          items.push(item);
        }

        handleNewItems(store, { items, skipSave, total: response.data.total });
        handleNewLooseItems(store, items);

        commit('SET_LOADING', false);
        return { items, total: response.data.total };
      })
      .catch(err => {
        console.log(err);
        commit('SET_LOADING', false);
      });
  },
  getPaginatedItems(store, params) {
    const { commit, dispatch } = store;
    commit('SET_LOADING', true);
    return dispatch('fetchList', params)
      .then(async response => {
        const skipSave = params && params.options && params.options.skipSave;

        const items = [];
        for (const dto of response.data.items) {
          const item = await dispatch('transformDto', dto);
          items.push(item);
        }

        handleNewPaginedItems(store, { items, skipSave, total: response.data.total });
        handleNewLooseItems(store, items);

        commit('SET_LOADING', false);
        return { items, total: response.data.total };
      })
      .catch(() => {
        commit('SET_LOADING', false);
      });
  },
  getSimpleItem({ state, dispatch }, params) {
    const identifier = {};
    parseSelector(params.selector, identifier);
    if (identifier.id !== undefined) {
      identifier[state.backendEntityIDField] = identifier.id;
      delete identifier.id;
    }
    params.query = Object.assign({}, params.query, identifier);
    return dispatch('getItems', params).then(({ items, total }) => {
      if (total !== 1 || items.length !== 1) {
        throw new Error();
      }
      const item = items[0];
      return Promise.resolve(item);
    });
  },
  persistItem({ commit, dispatch, state }, item) {
    commit('SET_LOADING', true);
    if (item.id) {
      return dispatch('update', item)
        .then(response => {
          commit('UPSERT_LOOSE_ITEM', item);
          commit('UPSERT_ITEM', item);
          commit('SET_LOADING', false);
          if (response.data !== '') {
            const dto = response.data;
            return dispatch('transformDto', dto).then(item => {
              return item;
            });
          }
          return item;
        })
        .catch(error => {
          commit('SET_LOADING', false);
          return Promise.reject(error);
        });
    } else {
      return dispatch('create', item)
        .then(response => {
          const dto = response.data;
          return dispatch('transformDto', dto).then(item => {
            commit('UPSERT_LOOSE_ITEM', item);
            commit('UPSERT_ITEM', item);
            commit('SET_TOTAL_ITEM_COUNT', state.totalItemsCount + 1);
            commit('SET_LOADING', false);
            return item;
          });
        })
        .catch(error => {
          commit('SET_LOADING', false);
          return Promise.reject(error);
        });
    }
  },
  replaceItems({ commit, dispatch }, params) {
    commit('CLEAR_ITEMS');
    return dispatch('getItems', params);
  },
  replacePaginatedItems({ commit, dispatch }, params) {
    commit('CLEAR_PAGINATED_ITEMS');
    return dispatch('getPaginatedItems', params);
  }
};

export default baseActions;
