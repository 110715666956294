<template>
  <div class="datePicker">
    <span class="material-input-bar" />
    <label class="material-label">
      <slot />
    </label>
    <br v-if="$slots['default']" />
    <el-date-picker
      v-if="!disabled"
      ref="datepicker"
      v-model="currentValue"
      :type="type"
      :format="format"
      :name="name"
      :placeholder="fillPlaceHolder"
      :readonly="readonly"
      :required="required"
      :picker-options="weekPickerOptions"
      @keydown.native="onKeyDown"
      @input="handleModelInput"
      @blur="handleBlur"
    />
    <span v-if="disabled" class="datePicker-disabled">{{ valueString }}</span>
  </div>
</template>

<script>
import { formatDateByElementFormat } from '@/utils/date';
import { autocompleteDate } from './date-input-utils';
import { parseDateInput } from './date-parser';

// Credits: http://blog.vishalon.net/index.php/javascript-getting-and-setting-caret-position-in-textarea/
function setCaretPosition(ctrl, pos) {
  // Modern browsers
  if (ctrl.setSelectionRange) {
    ctrl.focus();
    ctrl.setSelectionRange(pos, pos);

    // IE8 and below
  } else if (ctrl.createTextRange) {
    const range = ctrl.createTextRange();
    range.collapse(true);
    range.moveEnd('character', pos);
    range.moveStart('character', pos);
    range.select();
  }
}
export default {
  props: {
    disabled: Boolean,
    format: {
      default: 'dd-MM-yyyy',
      type: String
    },
    name: String,
    placeholder: {
      default: '',
      type: String
    },
    readonly: Boolean,
    required: {
      default: true,
      type: Boolean
    },
    value: [String, Number],
    type: {
      type: String,
      default: 'date'
    }
  },
  data() {
    return {
      currentValue: this.value,
      pendingUserInput: ''
    };
  },
  computed: {
    fillPlaceHolder() {
      return this.currentValue ? '' : this.placeholder;
    },
    valueString() {
      const dt = new Date(this.currentValue).toString();
      return this.currentValue && formatDateByElementFormat(dt, this.format);
    },
    weekPickerOptions() {
      return {
        firstDayOfWeek: 1
      };
    }
  },
  watch: {
    value(newValue) {
      this.currentValue = newValue;
      if (newValue && typeof newValue === 'string') {
        const parsedValue = this.handleModelInput(newValue);
        this.$emit('init', parsedValue);
      }
    }
  },
  methods: {
    onKeyDown(e) {
      const { $refs } = this;
      const Text = $refs.datepicker.userInput || $refs.datepicker.displayValue || '';
      const parsed = parseDateInput(e, Text);
      if (parsed) {
        this.forceInputText(e.target, parsed.Text, parsed.SelStart);
        this.pendingUserInput = parsed.Text;
      }
    },
    forceInputText(inputEl, text, caretPosition) {
      this.$refs.datepicker.userInput = text;
      this.$nextTick(_ => {
        setCaretPosition(inputEl, caretPosition);
      });
    },
    setSoftFocus() {
      if (this.$refs.datepicker) {
        this.$refs.datepicker.focus();

        this.$nextTick(() => {
          this.$refs.datepicker.hidePicker();
        });
      }
    },
    setFocus() {
      if (this.$refs.datepicker) {
        this.$refs.datepicker.focus();
      }
    },
    focus() {
      if (this.value) {
        this.setSoftFocus();
      } else {
        this.setFocus();
      }
    },
    handleBlur(e) {
      if (this.pendingUserInput) {
        const newValue = autocompleteDate(this.pendingUserInput);
        this.pendingUserInput = '';
        if (newValue) {
          this.$nextTick(_ => {
            this.handleModelInput(newValue);
          });
        }
      }
    },
    handleModelInput(event) {
      const value = event && Date.parse(event);
      this.$emit('input', value);
      this.$emit('change', value);
      return value;
    }
  }
};
</script>

<style scoped>
.datePicker-disabled {
  margin-top: 5px;
  display: block;
}
</style>

<style>
.material-label {
  color: #9e9e9e;
}
</style>
