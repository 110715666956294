import request from '@/utils/request';

export function apiFetchList(code) {
  let url = '/option/';
  if (code) {
    url += code;
  }
  return request({
    method: 'get',
    url: url
  });
}
export const apiOverrides = { apiFetchList };

export const storeExtensions = {
  actions: {
    update({ commit }, item) {
      throw new Error('Options are not updatable');
    },
    create({ commit }, item) {
      throw new Error('Options are not creatable');
    },
    delete({ commit }, item) {
      throw new Error('Options are not deletable');
    },
    fetchAllOptions({ commit, dispatch, state }) {
      dispatch('getItems').then(() => {
        commit('UPDATE_ALL_OPTIONS');
      });
    },
    fetchOptions({ commit, dispatch, state }, code) {
      dispatch('getItems', code).then(() => {
        commit('UPDATE_OPTIONS', code);
      });
    }
  },
  mutations: {
    UPDATE_OPTIONS: (state, code) => {
      const loadedOptions = {};
      loadedOptions[code] = state.items.filter(option => option.optionGroupCode === code);
      state.options = Object.assign({}, state.options, loadedOptions);
    },
    UPDATE_ALL_OPTIONS: state => {
      const allOptions = {};
      const allOptionGroupCodes = [...new Set(state.items.map(option => option.optionGroupCode))];
      allOptionGroupCodes.forEach(code => {
        allOptions[code] = state.items.filter(option => option.optionGroupCode === code);
      });
      state.options = Object.assign({}, state.options, allOptions);
    }
  },
  getters: {
    loadedOptions: state => code => {
      return state.options[code] || [];
    }
  },
  state: {
    options: {}
  }
};
