import i18n from '@/i18n';
import { Notification, Message } from 'element-ui';
import PromptDate from '@/components/PromptDate';
import PromptText from '@/components/PromptText';
import ActionDialogConfirm from '@/components/ActionDialogConfirm';
import { hasValue } from '@/utils';
export default {
  namespaced: true,
  state: {
    disabledErrorPrefixes: [],
    disabledErrorHTTPCodes: []
  },
  actions: {
    enableErrorCodePrefix({ commit }, prefix) {
      commit('ENABLE_ERROR_PREFIX', prefix);
    },
    disableErrorCodePrefix({ commit }, prefix) {
      commit('DISABLE_ERROR_PREFIX', prefix);
    },
    enableErrorHTTPCode({ commit }, code) {
      commit('ENABLE_HTTP_RESPONSE_PREFIX', code);
    },
    disableErrorHTTPCode({ commit }, code) {
      commit('DISABLE_HTTP_RESPONSE_CODE', code);
    },
    toast({ commit }, toast) {
      const { type, message } = toast;
      Message({
        message,
        type: type || 'error'
      });
    },
    success({ commit }, messageObj) {
      const { title, duration, message } = messageObj;

      return Notification({
        duration: duration || 2000,
        title: title || i18n.t('common.success'),
        message: message || '',
        type: 'success'
      });
    },
    error({ commit }, messageObj) {
      const { title, duration, message, dangerouslyUseHTMLString } = messageObj || {};

      return Notification({
        duration: duration || 4000,
        title: title || i18n.t('common.error'),
        message: message || i18n.t('error.general_request'),
        dangerouslyUseHTMLString: !!dangerouslyUseHTMLString,
        type: 'error'
      });
    },
    confirm(
      { dispatch },
      {
        title,
        message,
        cancelButtonText,
        confirmButtonText,
        type,
        dangerouslyUseHTMLString,
        width,
        closeOnClickModal,
        closeOnPressEscape,
        showClose,
        focusCancel
      }
    ) {
      return dispatch('actionDialog', {
        message,
        title: title || i18n.t('common.warning'),
        cancelButtonText: cancelButtonText || i18n.t('common.cancel'),
        confirmButtonText: confirmButtonText || i18n.t('common.confirm'),
        type: type || 'warning',
        dangerouslyUseHTMLString,
        width: width || '420px',
        closeOnClickModal: hasValue(closeOnClickModal) ? closeOnClickModal : false,
        closeOnPressEscape: hasValue(closeOnPressEscape) ? closeOnPressEscape : true,
        showClose: hasValue(showClose) ? showClose : true,
        focusCancel
      });
    },
    alert(
      { dispatch },
      {
        title,
        message,
        cancelButtonText,
        confirmButtonText,
        type,
        dangerouslyUseHTMLString,
        width,
        closeOnClickModal,
        closeOnPressEscape,
        showClose
      }
    ) {
      return dispatch('actionDialog', {
        message,
        title: title || i18n.t('common.warning'),
        cancelButtonText: cancelButtonText || i18n.t('common.cancel'),
        confirmButtonText: confirmButtonText || i18n.t('common.confirm'),
        type: type || 'warning',
        dangerouslyUseHTMLString,
        width: width || '420px',
        closeOnClickModal: hasValue(closeOnClickModal) ? closeOnClickModal : false,
        closeOnPressEscape: hasValue(closeOnPressEscape) ? closeOnPressEscape : false,
        showClose: hasValue(showClose) ? showClose : false,
        showCancel: false
      });
    },
    messageWindow(
      { dispatch },
      {
        title,
        message,
        confirmButtonText,
        type,
        dangerouslyUseHTMLString,
        width,
        closeOnClickModal,
        closeOnPressEscape,
        showClose,
        focusCancel
      }
    ) {
      return dispatch('actionDialog', {
        message,
        title: title || i18n.t('common.warning'),
        confirmButtonText: confirmButtonText || i18n.t('common.confirm'),
        type: type || 'warning',
        dangerouslyUseHTMLString,
        width: width || '420px',
        closeOnClickModal: hasValue(closeOnClickModal) ? closeOnClickModal : false,
        closeOnPressEscape: hasValue(closeOnPressEscape) ? closeOnPressEscape : true,
        showClose: hasValue(showClose) ? showClose : true,
        showCancel: false,
        focusCancel
      });
    },
    promptDate({ state, rootGetters }, options) {
      const {
        defaultValue,
        title,
        message,
        cancelButtonText,
        confirmButtonText,
        type,
        width,
        closeOnClickModal,
        closeOnPressEscape,
        showCancel,
        showClose,
        focusCancel
      } = options;
      return PromptDate({
        message,
        defaultValue,
        type: type || 'question',
        title: title || i18n.t('common.question'),
        cancelButtonText,
        confirmButtonText,
        closeOnClickModal: hasValue(closeOnClickModal) ? closeOnClickModal : false,
        closeOnPressEscape: hasValue(closeOnPressEscape) ? closeOnPressEscape : false,
        showCancel,
        showClose: hasValue(showClose) ? showClose : false,
        width: width || '320px',
        dateFormat: rootGetters['dateFormat'],
        focusCancel
      });
    },
    prompt({ state, rootGetters }, options) {
      const {
        defaultValue,
        title,
        message,
        cancelButtonText,
        confirmButtonText,
        type,
        width,
        closeOnClickModal,
        closeOnPressEscape,
        showCancel,
        showClose
      } = options;
      return PromptText({
        message,
        defaultValue,
        type: type || 'question',
        title: title || i18n.t('common.question'),
        cancelButtonText,
        confirmButtonText,
        closeOnClickModal: hasValue(closeOnClickModal) ? closeOnClickModal : false,
        closeOnPressEscape: hasValue(closeOnPressEscape) ? closeOnPressEscape : false,
        showCancel,
        showClose: hasValue(showClose) ? showClose : false,
        width: width || '320px'
      });
    },
    actionDialog({ state }, options) {
      const {
        title,
        message,
        cancelButtonText,
        confirmButtonText,
        type,
        dangerouslyUseHTMLString,
        width,
        closeOnClickModal,
        closeOnPressEscape,
        showCancel,
        showClose,
        focusCancel
      } = options;
      return ActionDialogConfirm({
        message,
        title: title || i18n.t('common.warning'),
        cancelButtonText: cancelButtonText || i18n.t('common.cancel'),
        confirmButtonText: confirmButtonText || i18n.t('common.confirm'),
        type: type || 'warning',
        dangerouslyUseHTMLString,
        closeOnClickModal,
        closeOnPressEscape,
        showCancel,
        showClose,
        width,
        focusCancel
      });
    },
    requestError({ state, dispatch }, err) {
      if (err.response) {
        if (!state.disabledErrorHTTPCodes.some(code => err.response.status === code)) {
          const { data } = err.response;
          if (Array.isArray(data)) {
            // Display error from database if that exists
            data.forEach(e => {
              const errCode = e.code || e.Code;
              const errMessage = e.message || e.Message;
              if (!state.disabledErrorPrefixes.some(prefix => errCode && errCode.startsWith(prefix))) {
                dispatch('error', { message: errMessage || errCode });
              }
            });
          } else {
            dispatch('requestErrorByStatus', err.response.status);
          }
        }
      } else {
        dispatch('toast', { message: err.message });
      }
    },
    requestErrorByStatus({ dispatch }, status) {
      switch (status) {
        case 401:
          return dispatch('error', { message: i18n.t('error.request_401') });
        case 403:
          return dispatch('error', { message: i18n.t('error.request_403') });
        default:
          return dispatch('error', { message: i18n.t('error.general_request') });
      }
    },
    formValidationFailed({ dispatch }) {
      dispatch('toast', { message: i18n.t('validation.formValidationFailed') });
    },
    dataCreated({ dispatch }) {
      dispatch('success', { message: i18n.t('common.data_created') });
    },
    dataUpdated({ dispatch }) {
      dispatch('success', { message: i18n.t('common.data_updated') });
    },
    dataSaveError({ dispatch }) {
      dispatch('error', { message: i18n.t('common.data_save_error') });
    },
    deleteCompleted({ dispatch }) {
      dispatch('success', { message: i18n.t('common.delete_completed') });
    },
    deleteCancelled({ dispatch }) {
      dispatch('toast', { message: i18n.t('common.delete_cancelled') });
    },
    deleteConfirm({ dispatch }, summary) {
      if (summary) {
        return dispatch('confirm', {
          message: i18n.t('common.delete_record_summary_warning', { summary }),
          confirmButtonText: i18n.t('common.delete'),
          dangerouslyUseHTMLString: true,
          focusCancel: true
        });
      } else {
        return dispatch('confirm', {
          message: i18n.t('common.delete_record_warning'),
          confirmButtonText: i18n.t('common.delete'),
          focusCancel: true
        });
      }
    },
    closeUnsavedConfirm({ dispatch }) {
      return dispatch('confirm', {
        title: i18n.t('common.warning'),
        message: i18n.t('common.close_unsaved_warning'),
        cancelButtonText: i18n.t('common.stay'),
        confirmButtonText: i18n.t('common.close')
      });
    }
  },
  getters: {},
  mutations: {
    ENABLE_ERROR_PREFIX: (state, prefix) => {
      const index = state.disabledErrorPrefixes.indexOf(prefix);
      if (index > -1) {
        state.disabledErrorPrefixes.splice(index, 1);
      }
    },
    DISABLE_ERROR_PREFIX: (state, prefix) => {
      const index = state.disabledErrorPrefixes.indexOf(prefix);
      if (index < 0) {
        state.disabledErrorPrefixes.push(prefix);
      }
    },
    ENABLE_HTTP_RESPONSE_PREFIX: (state, code) => {
      const index = state.disabledErrorHTTPCodes.indexOf(code);
      if (index > -1) {
        state.disabledErrorHTTPCodes.splice(index, 1);
      }
    },
    DISABLE_HTTP_RESPONSE_CODE: (state, code) => {
      state.disabledErrorHTTPCodes.push(code);
    }
  }
};
