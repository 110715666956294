import Vue from 'vue';
import VueI18n from 'vue-i18n';
import nlElementUiMessages from 'element-ui/lib/locale/lang/nl';
import deElementUiMessages from 'element-ui/lib/locale/lang/de';
import nlAppMessages from './nl';
import deAppMessages from './de';

Vue.use(VueI18n);

const messages = {
  de: {
    ...deElementUiMessages,
    ...deAppMessages
  },
  nl: {
    ...nlElementUiMessages,
    ...nlAppMessages
  }
};

export default new VueI18n({
  locale: 'nl',
  messages
});
