<template>
  <div class="action-dialog">
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :width="width"
      :before-close="handleClose"
      :close-on-click-modal="closeOnClickModal"
      :show-close="showClose"
      :close-on-press-escape="closeOnPressEscape"
    >
      <div class="action-dialog-body">
        <div v-if="icon" class="icon-container" :class="type">
          <i :class="icon"></i>
        </div>
        <div class="message-container">
          <slot>
            <p v-if="dangerouslyUseHTMLString" v-html="message"></p>
            <p v-if="!dangerouslyUseHTMLString">{{ message }}</p>
          </slot>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="footer-block footer-left">
          <slot name="footer-left" />
        </div>
        <div class="footer-block footer-right">
          <el-button v-if="showCancel" ref="btnCancel" @click="handleAction('cancel')">
            {{ cancelButtonText || i18n.t('common.cancel') }}
          </el-button>
          <el-button ref="btnConfirm" type="primary" @click="handleAction('confirm')">
            {{ confirmButtonText || i18n.t('common.confirm') }}
          </el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import i18n from '@/i18n';
export default {
  props: {
    callback: {
      type: Function,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    message: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    cancelButtonText: String,
    confirmButtonText: String,
    dangerouslyUseHTMLString: {
      type: Boolean,
      default: false
    },
    width: String,
    closeOnClickModal: {
      type: Boolean,
      default: true
    },
    closeOnPressEscape: {
      type: Boolean,
      default: true
    },
    showCancel: {
      type: Boolean,
      default: true
    },
    showClose: {
      type: Boolean,
      default: true
    },
    focusCancel: {
      type: Boolean
    },
    beforeSubmit: Function
  },
  data() {
    return {
      dialogVisible: true,
      i18n
    };
  },
  computed: {
    icon() {
      switch (this.type) {
        case 'warning':
          return 'el-icon-warning';
        case 'info':
          return 'el-icon-info';
        case 'question':
          return 'el-icon-question';
        default:
          return '';
      }
    }
  },
  mounted() {
    this.$nextTick(_ => {
      this.showCancel && this.focusCancel ? this.$refs.btnCancel.$el.focus() : this.$refs.btnConfirm.$el.focus();
    });
  },
  methods: {
    async handleAction(action) {
      if (action === 'confirm' && this.beforeSubmit) {
        try {
          await this.beforeSubmit();
        } catch {
          return;
        }
      }
      if (action === 'confirm' || action === 'cancel') {
        this.dialogVisible = false;
      }
      setTimeout(() => {
        this.callback(action, this);
      });
      this.$emit('action', action);
    },
    async handleClose(done) {
      await this.handleAction('close');
      done();
    }
  }
};
</script>
<style lang="scss">
.action-dialog {
  .el-dialog__body {
    padding: 10px 15px;
  }
}
</style>
<style lang="scss" scoped>
.action-dialog {
  .action-dialog-body {
    position: relative;
    .icon-container {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      font-size: 24px !important;
      display: inline-block;
      &.warning {
        color: #e6a23c;
      }
      & + .message-container {
        padding-left: 36px;
        padding-right: 12px;
      }
    }
    .message-container {
      p {
        line-height: 24px;
      }
    }
  }
  .footer-left {
    float: left;
  }
}
</style>
