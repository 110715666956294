import ActivityBasedTimer from '@/utils/browser/ActivityBasedTimer';
import store from '@/store';

async function triggerSWUpdate() {
  if (store.getters['newVersionAvailable']) {
    return;
  }
  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  if (!navigator.serviceWorker) {
    console.warn('Could not find serviceWorker instance in navigator object');
    return;
  }

  const registration = await navigator.serviceWorker.getRegistration();
  registration && registration.update();
}

export default {
  created() {
    triggerSWUpdate();
    ActivityBasedTimer.setInterval({
      async callback() {
        await triggerSWUpdate();
      },
      interval: this.$store.getters['minUpdateCheckInterval'],
      forcedInterval: this.$store.getters['maxUpdateCheckInterval']
    });
  },
  computed: {
    newVersionAvailable() {
      return this.$store.getters['newVersionAvailable'];
    }
  },
  watch: {
    newVersionAvailable(newVersionAvailable) {
      if (newVersionAvailable) {
        store
          .dispatch('notify/alert', {
            title: this.$t('autoUpdate.alertTitle'),
            message: this.$t('autoUpdate.alertMessage'),
            confirmButtonText: this.$t('autoUpdate.alertConfirm')
          })
          .then(() => {
            location.reload();
          });
      }
    }
  }
};
