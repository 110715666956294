'use strict';
import { isGiven, isStringNumberWithinRange } from '@/utils';
import { isLeap } from '@/utils/date';
const dayjs = require('dayjs');

const monthLimitsNonLeapYear = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
const monthLimitsLeapYear = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

export function autocompleteDate(inputDateString, baseDate) {
  if (!isLegalInputDateString(inputDateString)) {
    return;
  }
  let month, day, year;

  const inputDateStringTrimmed = inputDateString.replace(/ /g, '');
  const inputDateArray = inputDateStringTrimmed.split('-');

  const inputContainsDayMonthYear = inputDateArray.length === 3 && !inputDateArray.some(x => x === '');
  const inputContainsDayMonth =
    (inputDateArray.length === 2 || inputDateArray.length === 3) &&
    isGiven(inputDateArray[0]) &&
    isGiven(inputDateArray[1]);

  if (inputContainsDayMonthYear) {
    [day, month, year] = inputDateArray;
  } else if (inputContainsDayMonth) {
    [day, month] = inputDateArray;
  } else {
    const inputDateStringDigits = inputDateStringTrimmed.replace(/\-/g, '');
    if (inputDateStringDigits.length === 1) {
      day = inputDateStringDigits;
    } else if (inputDateStringDigits.length === 2) {
      ({ day, month } = splitDayMonth(inputDateStringDigits));
    } else if (inputDateStringDigits.length >= 3) {
      ({ day, month } = splitDayMonth(inputDateStringDigits.substring(0, 2)));
      ({ month, year } = splitMonthYear(inputDateStringDigits.substring(day.length)));
    }
  }
  const baseDateDayJS = dayjs(baseDate);

  year = autofillCentury(baseDateDayJS.year(), year);

  day = isGiven(day) ? day.padStart(2, '0') : baseDateDayJS.format('DD');
  month = isGiven(month) ? month.padStart(2, '0') : baseDateDayJS.format('MM');
  year = isGiven(year) ? year.padStart(4, '0') : baseDateDayJS.format('YYYY');

  day = capDayUpperLimitOfMonth(day, month, year);
  return `${year}-${month}-${day}`;
}

function isLegalInputDateString(str) {
  const re = /^([0-9\- ]*)$/;
  return re.test(str);
}

function splitDayMonth(digitsString) {
  const parsedString = digitsString.substring(0, 2);

  let day = '';
  let month = '';
  if (isStringNumberWithinRange(parsedString, 1, 31)) {
    day = parsedString;
  } else {
    day = parsedString[0];
    month = parsedString[1];
  }
  return { day, month };
}

function splitMonthYear(digitsString) {
  const parsedString = digitsString.substring(0, 6);

  let month = '';
  let year = '';

  const firstTwoDigits = parsedString.substring(0, 2);
  if (isStringNumberWithinRange(firstTwoDigits, 1, 12)) {
    month = firstTwoDigits;
  } else {
    month = parsedString[0];
  }
  year = parsedString.substring(month.length);

  return { month, year };
}

function autofillCentury(baseYear, year) {
  if (isGiven(year) && [1, 2].includes(year.length)) {
    const baseCentury = (baseYear % 100) * 100;
    const proposedYear = baseCentury + +year;
    const diff = proposedYear - baseYear;
    if (diff < -80 || diff > 20) {
      year = `${baseCentury - 100 + +year}`;
    } else {
      year = `${proposedYear}`;
    }
  }
  return year;
}

function capDayUpperLimitOfMonth(day, month, year) {
  let monthLimits = monthLimitsNonLeapYear;
  if (isLeap(+year)) {
    monthLimits = monthLimitsLeapYear;
  }
  const monthLimit = monthLimits[+month - 1];
  if (+day > monthLimit) {
    return `${monthLimit}`.padStart(2, '0');
  }
  return day;
}
