<template>
  <input
    v-if="!readonly"
    ref="input"
    v-model="inputModel"
    :placeholder="placeholder"
    :class="classNames"
    :disabled="disabled"
    @input="handleInput($event.target.value)"
    @focus="onFocusHandler"
    @blur="onBlurHandler"
  />
  <span v-else>{{ value === null ? placeholder : inputModel }} </span>
</template>

<script>
export default {
  props: {
    value: Number,
    disabled: Boolean,
    placeholder: String,
    readonly: Boolean,
    unit: {
      type: String,
      default: 's'
    },
    valueOnFormatError: {
      type: Number,
      default: null
    },
    valueOnEmpty: {
      type: Number,
      default: null
    },
    secondsIndicator: {
      type: String,
      default: 's'
    },
    minutesIndicator: {
      type: String,
      default: 'm'
    },
    hoursIndicator: {
      type: String,
      default: 'h'
    }
  },
  data() {
    return {
      hasFormatError: false,
      inputModel: this.toInputText(this.value)
    };
  },
  computed: {
    classNames() {
      let computedClasses = '';
      if (this.hasFormatError) {
        computedClasses += ' has-format-error';
      }
      return computedClasses;
    }
  },
  watch: {
    value(newValue, oldValue) {
      this.softSetInputModelByValue(newValue);
    }
  },
  created() {
    if (!(this.unit === this.minutesIndicator || this.unit === this.secondsIndicator)) {
      throw new Error(
        `The unit '${this.unit}' is unknown. Only '${this.secondsIndicator}' or '${this.minutesIndicator}' are supported`
      );
    }
  },
  methods: {
    onFocusHandler(e) {
      this.$emit('focus', e);
    },
    onBlurHandler(e) {
      this.hardSetInputModelByValue(this.value);
      this.$emit('blur', e);
    },
    emit(value) {
      this.$emit('input', value);
    },
    handleInput(input) {
      this.emit(this.format(input));
    },
    softSetInputModelByValue(value) {
      if (this.toValue(this.inputModel) !== value) {
        this.hardSetInputModelByValue(value);
      }
    },
    hardSetInputModelByValue(value) {
      this.inputModel = this.toInputText(value);
    },
    format(text) {
      this.hasFormatError = false;
      return this.toValue(text, { onFormatError: _ => (this.hasFormatError = true) });
    },
    toInputText(value) {
      const textPartials = [];
      let valueInSeconds = this.unit === this.minutesIndicator ? value * 60 : value;
      const hours = Math.floor(valueInSeconds / 3600);
      valueInSeconds = valueInSeconds % 3600;
      if (hours) {
        textPartials.push(`${hours}${this.hoursIndicator}`);
      }

      const minutes = Math.floor(valueInSeconds / 60);
      if (minutes) {
        textPartials.push(`${minutes}${this.minutesIndicator}`);
      }

      const seconds = valueInSeconds % 60;
      if (this.unit === this.secondsIndicator && (seconds || !textPartials.length)) {
        textPartials.push(`${seconds}${this.secondsIndicator}`);
      }

      return textPartials.join(' ');
    },
    toValue(inputText, options) {
      options = Object.assign({ onFormatError: _ => {} }, options);

      let text = inputText && inputText.replace(/ /g, '');
      if (!text) {
        return this.valueOnEmpty;
      }
      const regexOnlyNumbers = /^\d*$/i;
      if (regexOnlyNumbers.test(text)) {
        text += this.unit;
      }
      const regexTimeNotation = new RegExp(
        `^((?<hours>\\d+)${this.hoursIndicator})?((?<minutes>\\d+)${this.minutesIndicator})?((?<seconds>\\d+)${this.secondsIndicator})?$`,
        'g'
      );
      const matchObj = regexTimeNotation.exec(text);
      if (matchObj == null) {
        options.onFormatError();
        return this.valueOnFormatError;
      }
      const { hours, minutes, seconds } = matchObj.groups;
      const factor = this.unit === this.minutesIndicator ? 60 : 1;
      let duration = (+hours || 0) * 3600 + (+minutes || 0) * 60 + (+seconds || 0);
      duration = duration / factor;

      return duration;
    }
  }
};
</script>
<style lang="scss" scoped>
input.has-format-error {
  background-color: #fccfd5;
}
</style>
