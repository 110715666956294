const getters = {
  avatar: state => state.authentication.avatar,
  cachedViews: state => state.tagsView.cachedViews,
  device: state => state.app.device,
  domains: state => state.authentication.domains,
  language: state => state.app.language,
  name: state => state.authentication.name,
  permission_routers: state => state.permission.routes,
  permissions: state => state.authentication.permissions,
  addRouters: state => state.permission.addRouters,
  sidebar: state => state.app.sidebar,
  dateTimeFormat: state => state.app.dateTimeFormat,
  size: state => state.app.size,
  dateFormat: state => state.app.dateFormat,
  visitedViews: state => state.tagsView.visitedViews,
  errorLogs: state => state.errorLog.logs,
  token: state => state.authentication.token,
  roles: state => state.authentication.roles,
  setting: state => state.authentication.setting,
  status: state => state.authentication.status,
  timeFormat: state => state.app.timeFormat,
  userId: state => state.authentication.userId,
  pageVersion: state => state.app.pageVersion,
  editorOptions: state => state.app.editorOptions,
  multilingual: state => state.app.multilingual,
  validationErrorInputPrefix: state => state.app.validationErrorInputPrefix,
  newVersionAvailable: state => state.app.newVersionAvailable,
  maxUpdateCheckInterval: state => state.app.maxUpdateCheckInterval,
  minUpdateCheckInterval: state => state.app.minUpdateCheckInterval,
  productCategory: state => state.app.productCategory,
  productRegistrationGroup: state => state.app.productRegistrationGroup
};
export default getters;
