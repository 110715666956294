import request from '@/utils/request';
import { toDateTimeString } from '@/utils/date';

function transformItem2Dto(item) {
  const dto = {
    productID: item.id,
    ...item,
    inStockExpectedDateTime: toDateTimeString(item.inStockExpectedDateTime)
  };
  delete dto.id;
  return dto;
}
export const apiOverrides = { transformItem2Dto };

export const storeExtensions = {
  actions: {
    downloadImage({ dispatch }, id) {
      return request.get(`/product/image/${id}`, {
        responseType: 'blob'
      });
    },
    deleteImage({ dispatch }, id) {
      return request.delete(`/product/image/${id}`);
    },
    downloadProductInfo({ dispatch }, id) {
      return request.get(`/product/productinfo/${id}`, {
        responseType: 'blob'
      });
    },
    deleteProductInfo({ dispatch }, id) {
      return request.delete(`/product/productinfo/${id}`);
    }
  }
};
