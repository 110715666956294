import { RouteConfig } from 'vue-router';
import { PermissionLevel } from '@/utils/permission';

const LazyLayout = () => import('@/layout/index.vue');

export default [
  {
    children: [
      {
        component: () => import('@/pages/admin/security/roles/index.vue'),
        meta: {
          permissions: [{ Role: PermissionLevel.Read }],
          title: 'menu_admin_roles'
        },
        name: 'Roles',
        path: 'roles'
      },
      {
        component: () => import('@/pages/admin/security/roles/create.vue'),
        hidden: true,
        meta: {
          icon: 'edit',
          permissions: [{ Role: PermissionLevel.Write }],
          title: 'createRole'
        },
        name: 'CreateRole',
        path: 'roles/create'
      },
      {
        component: () => import('@/pages/admin/security/roles/edit.vue'),
        hidden: true,
        meta: {
          noCache: true,
          permissions: [{ Role: PermissionLevel.Read }],
          title: 'editRole'
        },
        name: 'EditRole',
        path: 'roles/edit/:id(\\d+)'
      },
      {
        component: () => import('@/pages/admin/security/users/index.vue'),
        meta: {
          permissions: [{ User: PermissionLevel.Read }],
          title: 'menu_admin_users'
        },
        name: 'Users',
        path: 'users'
      },
      {
        component: () => import('@/pages/admin/security/users/create.vue'),
        hidden: true,
        meta: {
          icon: 'edit',
          permissions: [{ User: PermissionLevel.Write }],
          title: 'createUser'
        },
        name: 'CreateUser',
        path: 'users/create'
      },
      {
        component: () => import('@/pages/admin/security/users/edit.vue'),
        hidden: true,
        meta: {
          noCache: true,
          permissions: [{ User: PermissionLevel.Read }],
          title: 'editUser'
        },
        name: 'EditUser',
        path: 'users/edit/:id(\\d+)'
      },

      {
        component: () => import('@/pages/admin/security/externalApiUsers/index.vue'),
        meta: {
          permissions: [{ ExternalApiUser: PermissionLevel.Read }],
          title: 'menu_admin_external_api_users',
          refreshOnDomainChange: true
        },
        name: 'ExternalApiUsers',
        path: 'externalApiUsers'
      },
      {
        component: () => import('@/pages/admin/security/externalApiUsers/create.vue'),
        hidden: true,
        meta: {
          icon: 'edit',
          permissions: [{ ExternalApiUser: PermissionLevel.Write }],
          title: 'createExternalApiUser',
          disableDomainChange: true
        },
        name: 'CreateExternalApiUser',
        path: 'externalApiUsers/create'
      },
      {
        component: () => import('@/pages/admin/security/externalApiUsers/edit.vue'),
        hidden: true,
        meta: {
          noCache: true,
          permissions: [{ ExternalApiUser: PermissionLevel.Read }],
          title: 'editExternalApiUser',
          disableDomainChange: true
        },
        name: 'EditExternalApiUser',
        path: 'externalApiUsers/edit/:id(\\d+)'
      }
    ],
    component: LazyLayout,
    meta: { icon: 'peoples', title: 'menu_admin_security' },
    name: 'Security',
    path: '/security'
  } as RouteConfig, //
  {
    children: [
      {
        component: () => import('@/pages/admin/application/countries/index.vue'),
        meta: {
          permissions: [{ Country: PermissionLevel.Read }],
          title: 'menu_admin_countries'
        },
        name: 'Countries',
        path: 'countries'
      },
      {
        component: () => import('@/pages/admin/application/countries/create.vue'),
        hidden: true,
        meta: {
          icon: 'edit',
          permissions: [{ Country: PermissionLevel.Write }],
          title: 'createCountry'
        },
        name: 'CreateCountry',
        path: 'countries/create'
      },
      {
        component: () => import('@/pages/admin/application/countries/edit.vue'),
        hidden: true,
        meta: {
          noCache: true,
          permissions: [{ Country: PermissionLevel.Read }],
          title: 'editCountry'
        },
        name: 'EditCountry',
        path: 'countries/edit/:id'
      },
      {
        component: () => import('@/pages/admin/application/languages/index.vue'),
        meta: {
          permissions: [{ Language: PermissionLevel.Read }],
          title: 'menu_admin_languages'
        },
        name: 'Languages',
        path: 'languages'
      },
      {
        component: () => import('@/pages/admin/application/languages/create.vue'),
        hidden: true,
        meta: {
          icon: 'edit',
          permissions: [{ Language: PermissionLevel.Write }],
          title: 'createLanguage'
        },
        name: 'CreateLanguage',
        path: 'languages/create'
      },
      {
        component: () => import('@/pages/admin/application/languages/edit.vue'),
        hidden: true,
        meta: {
          noCache: true,
          permissions: [{ Language: PermissionLevel.Read }],
          title: 'editLanguage'
        },
        name: 'EditLanguage',
        path: 'languages/edit/:id'
      },
      {
        component: () => import('@/pages/admin/application/translations/index.vue'),
        meta: {
          permissions: [{ Translation: PermissionLevel.Read }],
          title: 'menu_admin_translations'
        },
        name: 'Translations',
        path: 'translations'
      },
      {
        component: () => import('@/pages/admin/application/translations/edit.vue'),
        hidden: true,
        meta: {
          noCache: true,
          permissions: [{ Translation: PermissionLevel.Read }],
          title: 'editTranslation'
        },
        name: 'EditTranslation',
        path: 'translations/edit/:id'
      },
      {
        component: () => import('@/pages/admin/application/configuration/index.vue'),
        meta: {
          permissions: [{ Configuration: PermissionLevel.Read }],
          title: 'menu_admin_configuration'
        },
        name: 'Configuration',
        path: 'configuration'
      },
      {
        component: () => import('@/pages/admin/application/configuration/edit.vue'),
        hidden: true,
        meta: {
          noCache: true,
          permissions: [{ Configuration: PermissionLevel.Read }],
          title: 'editConfiguration'
        },
        name: 'EditConfiguration',
        path: 'configuration/edit/:id'
      },
      {
        component: () => import('@/pages/admin/application/domains/index.vue'),
        hidden: false,
        meta: {
          permissions: [{ Domain: PermissionLevel.Read }],
          title: 'menu_admin_domains',
          refreshOnDomainChange: true
        },
        name: 'Domains',
        path: 'domains'
      },
      {
        component: () => import('@/pages/admin/application/domains/create.vue'),
        hidden: true,
        meta: {
          icon: 'edit',
          permissions: [{ Domain: PermissionLevel.Write }],
          title: 'createDomain',
          disableDomainChange: true
        },
        name: 'CreateDomain',
        path: 'domain/create'
      },
      {
        component: () => import('@/pages/admin/application/domains/edit.vue'),
        hidden: true,
        meta: {
          noCache: true,
          permissions: [{ Domain: PermissionLevel.Read }],
          title: 'editDomain',
          disableDomainChange: true
        },
        name: 'EditDomain',
        path: 'domain/edit/:id(\\d+)'
      },
      {
        component: () => import('@/pages/admin/maintenance/accesslog/index.vue'),
        meta: {
          permissions: [{ AccessLog: PermissionLevel.Read }],
          title: 'menu_admin_accesslog'
        },
        name: 'Access log',
        path: 'accesslog'
      },
      {
        component: () => import('@/pages/admin/maintenance/eventlog/index.vue'),
        meta: {
          permissions: [{ EventLog: PermissionLevel.Read }],
          title: 'menu_admin_eventlog'
        },
        name: 'Event Log',
        path: 'eventlog'
      }
    ],
    component: LazyLayout,
    meta: { icon: 'star', title: 'menu_admin_application' },
    name: 'Application',
    path: '/application'
  } as RouteConfig,
  {
    children: [
      {
        component: () => import('@/pages/admin/customeraddresses/index.vue'),
        meta: {
          permissions: [{ CustomerAddress: PermissionLevel.Read }],
          title: 'menu_customer_addresses'
        },
        name: 'CustomerAddresses',
        path: 'customer-addresses'
      },
      {
        component: () => import('@/pages/admin/customeraddresses/create.vue'),
        hidden: true,
        meta: {
          permissions: [{ CustomerAddress: PermissionLevel.Write }],
          title: 'createCustomerAddress'
        },
        name: 'CreateCustomerAddress',
        path: 'customer-addresses/create'
      },
      {
        component: () => import('@/pages/admin/customeraddresses/edit.vue'),
        hidden: true,
        meta: {
          permissions: [{ CustomerAddress: PermissionLevel.Read }],
          title: 'editCustomerAddress'
        },
        name: 'EditCustomerAddress',
        path: 'customer-addresses/edit/:id(\\d+)'
      },

      {
        component: () => import('@/pages/admin/customers/index.vue'),
        meta: {
          permissions: [{ Customer: PermissionLevel.Read }],
          title: 'menu_customers'
        },
        name: 'Customers',
        path: 'customers'
      },
      {
        component: () => import('@/pages/admin/customers/create.vue'),
        hidden: true,
        meta: {
          permissions: [{ Customer: PermissionLevel.Write }],
          title: 'createCustomer'
        },
        name: 'CreateCustomer',
        path: 'customers/create'
      },
      {
        component: () => import('@/pages/admin/customers/edit.vue'),
        hidden: true,
        meta: {
          permissions: [{ Customer: PermissionLevel.Read }],
          title: 'editCustomer'
        },
        name: 'EditCustomer',
        path: 'customers/edit/:id(\\d+)'
      }
    ],
    component: LazyLayout,
    meta: { icon: 'tree', title: 'menu_admin_manage_customers' },
    name: 'ManageCustomers',
    path: '/manage-customers'
  } as RouteConfig,
  {
    children: [
      // PLOP: Application routes
      {
        component: () => import('@/pages/admin/depositclasses/index.vue'),
        meta: {
          permissions: [{ DepositClass: PermissionLevel.Read }],
          title: 'menu_deposit_classes'
        },
        name: 'DepositClasses',
        path: 'deposit-classes'
      },
      {
        component: () => import('@/pages/admin/depositclasses/create.vue'),
        hidden: true,
        meta: {
          permissions: [{ DepositClass: PermissionLevel.Write }],
          title: 'createDepositClass'
        },
        name: 'CreateDepositClass',
        path: 'deposit-classes/create'
      },
      {
        component: () => import('@/pages/admin/depositclasses/edit.vue'),
        hidden: true,
        meta: {
          permissions: [{ DepositClass: PermissionLevel.Read }],
          title: 'editDepositClass'
        },
        name: 'EditDepositClass',
        path: 'deposit-classes/edit/:id(\\d+)'
      },
      {
        component: () => import('@/pages/admin/productattributes/index.vue'),
        meta: {
          permissions: [{ ProductAttribute: PermissionLevel.Read }],
          title: 'menu_product_attributes'
        },
        name: 'ProductAttributes',
        path: 'product-attributes'
      },
      {
        component: () => import('@/pages/admin/productattributes/create.vue'),
        hidden: true,
        meta: {
          permissions: [{ ProductAttribute: PermissionLevel.Write }],
          title: 'createProductAttribute'
        },
        name: 'CreateProductAttribute',
        path: 'product-attributes/create'
      },
      {
        component: () => import('@/pages/admin/productattributes/edit.vue'),
        hidden: true,
        meta: {
          permissions: [{ ProductAttribute: PermissionLevel.Read }],
          title: 'editProductAttribute'
        },
        name: 'EditProductAttribute',
        path: 'product-attributes/edit/:id(\\d+)'
      },
      {
        component: () => import('@/pages/admin/productcategories/index.vue'),
        meta: {
          permissions: [{ ProductCategory: PermissionLevel.Read }],
          title: 'menu_product_categories'
        },
        name: 'ProductCategories',
        path: 'product-categories'
      },
      {
        component: () => import('@/pages/admin/productcategories/create.vue'),
        hidden: true,
        meta: {
          permissions: [{ ProductCategory: PermissionLevel.Write }],
          title: 'createProductCategory'
        },
        name: 'CreateProductCategory',
        path: 'product-categories/create'
      },
      {
        component: () => import('@/pages/admin/productcategories/edit.vue'),
        hidden: true,
        meta: {
          permissions: [{ ProductCategory: PermissionLevel.Read }],
          title: 'editProductCategory'
        },
        name: 'EditProductCategory',
        path: 'product-categories/edit/:id(\\d+)'
      },
      {
        component: () => import('@/pages/admin/productregistrationgroups/index.vue'),
        meta: {
          permissions: [{ ProductRegistrationGroup: PermissionLevel.Read }],
          title: 'menu_product_registration_groups'
        },
        name: 'ProductRegistrationGroups',
        path: 'product-registration-groups'
      },
      {
        component: () => import('@/pages/admin/productregistrationgroups/create.vue'),
        hidden: true,
        meta: {
          permissions: [{ ProductRegistrationGroup: PermissionLevel.Write }],
          title: 'createProductRegistrationGroup'
        },
        name: 'CreateProductRegistrationGroup',
        path: 'product-registration-groups/create'
      },
      {
        component: () => import('@/pages/admin/productregistrationgroups/edit.vue'),
        hidden: true,
        meta: {
          permissions: [{ ProductRegistrationGroup: PermissionLevel.Read }],
          title: 'editProductRegistrationGroup'
        },
        name: 'EditProductRegistrationGroup',
        path: 'product-registration-groups/edit/:id(\\d+)'
      },
      {
        component: () => import('@/pages/admin/products/index.vue'),
        meta: {
          permissions: [{ Product: PermissionLevel.Read }],
          title: 'menu_products'
        },
        name: 'Products',
        path: 'products'
      },
      {
        component: () => import('@/pages/admin/products/create.vue'),
        hidden: true,
        meta: {
          permissions: [{ Product: PermissionLevel.Write }],
          title: 'createProduct'
        },
        name: 'CreateProduct',
        path: 'products/create'
      },
      {
        component: () => import('@/pages/admin/products/edit.vue'),
        hidden: true,
        meta: {
          permissions: [{ Product: PermissionLevel.Read }],
          title: 'editProduct'
        },
        name: 'EditProduct',
        path: 'products/edit/:id(\\d+)'
      }
    ],
    component: LazyLayout,
    meta: { icon: 'shopping', title: 'menu_admin_manage_products' },
    name: 'ManageProducts',
    path: '/manage-products'
  } as RouteConfig,
  {
    children: [
      {
        component: () => import('@/pages/user/settings.vue'),
        meta: { noCache: true, title: 'userSettings' },
        name: 'Settings',
        path: 'settings'
      }
    ],
    component: LazyLayout,
    hidden: true,
    path: '/user',
    redirect: 'settings'
  } as RouteConfig
];
