// Keys ougt to be sorted alphabetically, case sensitive.
// To autosort: repeat CTRL+S until all keys are sorted
//  OR
// repeat command until sorted: npm run lint-fix

/* eslint sort-keys-fix/sort-keys-fix: error */
export default {
  accesslog: {
    accessedAt: 'Datum tijd',
    accessedBy: 'Geopend door',
    accessedResource: 'Gebruikte bron',
    ip: 'IP',
    parameters: 'Parameters'
  },
  autoUpdate: {
    alertConfirm: 'Herlaad pagina',
    alertMessage:
      'Er is een nieuwe versie van de applicatie beschikbaar. Herlaad de pagina om de applicatie bij te werken.',
    alertTitle: 'Update'
  },
  common: {
    add: 'Toevoegen',
    address: 'Adres',
    addressLineOne: 'Straat en huisnr',
    addressLineTwo: 'Extra adresregel',
    autoLoginUrl: 'Login link',
    billingAddress: 'Factuuradres',
    block_notification_not_saved_warning: 'Notificatie instelling niet opgeslagen. Probeer het later opnieuw',
    browserNotSupported: 'Uw browser ondersteunt deze functionaliteit niet',
    bulkOrders: 'Bulk bestellingen',
    cancel: 'Annuleren',
    chamberOfCommerceNumber: 'KvK nummer',
    city: 'Plaats',
    clearFilters: 'Verwijder alle filters',
    close: 'Sluiten',
    close_unsaved_warning: 'Uw wijzigingen zijn nog niet opgeslagen. Weet u zeker dat u dit scherm wilt sluiten?',
    close_warning: 'Wilt u zeker dat u dit scherm wilt afsluiten?',
    code: 'Code',
    companyAddress: 'Bezoekadres',
    companyName: 'Bedrijf',
    confirm: 'Bevestigen',
    copy: 'Kopieer',
    copyToClipboard: 'Kopiëer naar klembord',
    copyToClipboardFail: 'Kopiëren naar klembord mislukt',
    copyToClipboardSuccess: 'Kopiëren naar klembord gelukt',
    country: 'Land',
    coupledDepositProductNameOnWooCommerce: 'Naam statiegeld product op WooCommerce',
    customer: 'Klant',
    customerName: 'Klantnaam',
    customerSpecificCategoryName: 'Naam klantspecifieke categorie',
    data_created: 'De gegevens zijn opgeslagen',
    data_save_error: 'De gegevens kunnen niet worden opgeslagen',
    data_updated: 'De wijzigingen zijn opgeslagen',
    date: 'Datum',
    dateOnSaleFrom: 'Start korting',
    dateOnSaleTo: 'Einde korting',
    decimals: 'Decimalen',
    defaultProductCategory: 'Default productcategorie bij import',
    delete: 'Verwijderen',
    delete_cancelled: 'Verwijderen geannuleerd',
    delete_completed: 'De gegevens zijn verwijderd',
    delete_record_summary_warning: 'Weet u zeker dat u <b>{summary}</b> wilt verwijderen?',
    delete_record_warning: 'Weet u zeker dat u dit wilt verwijderen?',
    department: 'Afdeling',
    deposit: 'Statiegeld',
    depositClass: 'Statiegeld klasse',
    description: 'Beschrijving',
    discountRules: 'Kortingsacties',
    displayName: 'Weergave naam',
    domain: 'Domain',
    domainDescription: 'Beschrijving',
    domainName: 'Domeinnaam',
    dontSave: 'Niet opslaan',
    dontSend: 'Niet verzenden',
    dont_show_again_warning: 'Niet meer tonen',
    draft: 'Concept',
    ean: 'EAN-13',
    edit: 'Bewerk',
    email: 'E-mailadres',
    emptyString: ' ',
    error: 'Fout',
    export: 'Export',
    file: 'Bestand',
    firstName: 'Voornaam',
    fullDescription: 'Volledige beschrijving',
    gender: 'Geslacht',
    general: 'Algemeen',
    id: 'ID',
    image: 'Afbeelding',
    images: 'Afbeeldingen',
    inStockExpectedDateTime: 'Verwachte datum beschikbaarheid',
    inactive: 'Inactief',
    initials: 'Initialen',
    itemNumber: 'Artikelnummer',
    jobFunction: 'Functie',
    language: 'Taal',
    lastName: 'Achternaam',
    leave_mail_warning: 'U verlaat deze pagina. Wilt u eerst deze mail verzenden?',
    leave_unsaved_warning: 'U verlaat deze pagina. Wilt u de wijzigingen opslaan?',
    loading: 'Laden ...',
    mailingAddress: 'Postadres',
    modified: 'Gewijzigd',
    name: 'Naam',
    no: 'Nee',
    noData: 'Geen data',
    notApplicable: 'Niet van toepassing',
    ok: 'OK',
    overrideAddressOnWooCommerce: 'Verstuur naar WooCommerce, ongeacht adres wijziging via de webshop (eenmalig)',
    parent: 'Bovenliggend',
    parentName: 'Bovenliggend',
    password: 'Wachtwoord',
    pdfFile: 'PDF',
    phoneNumber: 'Telefoon',
    postCode: 'Postcode',
    postalCode: 'Postcode',
    prefix: 'Tussenvoegsel',
    product: 'Product',
    productAttribute: 'Product eigenschap',
    productAttributeItem: 'Product eigenschap item',
    productAttributeItems: 'Product eigenschap items',
    productCategory: 'Productcategorie',
    productCategoryName: 'Productcategorie',
    productRegistrationGroup: 'Omzet groep',
    productRegistrationGroupName: 'Omzet groep',
    protectedCategoryID: 'ID verborgen categorie',
    publishToWebshopYN: 'Publiceren op webshop',
    quantity: 'Aantal',
    question: 'Vraag',
    rawPrice: 'Ruwe prijs',
    readMore: 'Lees meer ...',
    reference: 'Referentie',
    remark: 'Opmerking',
    remarks: 'Opmerkingen',
    salePrice: 'Kortingsprijs',
    save: 'Opslaan',
    saveClose: 'Opslaan & sluiten',
    saveNext: 'Opslaan & volgende',
    search: 'Zoek',
    send: 'Verzenden',
    shippingAddress: 'Afleveradres',
    shortDescription: 'Korte omschrijving',
    skip: 'Overslaan',
    snelStartGroupID: 'SnelStart ID',
    snelStartID: 'SnelStart ID',
    snelStartKlantCode: 'Snelstart klantcode',
    status: 'Status',
    stay: 'Blijf op pagina',
    streetName: 'Straat',
    streetNumber: 'Huisnummer',
    success: 'Succes',
    switchOff: 'Uit',
    switchOn: 'Aan',
    syncError: 'Error',
    taskstatus: 'Taakstatus',
    title: 'Titel',
    topCategoryOnWooCommerceName: 'Naam Hoofdcategorie op WooCommerce',
    translations: 'Vertalingen',
    type: 'Type',
    unit: 'Eenheid',
    userName: 'Gebruikersnaam',
    username: 'Gebruikersnaam',
    vatNumber: 'BTW-nummer',
    vatPercentage: 'BTW percentage',
    warning: 'Waarschuwing',
    webshopURL: 'WooCommerce URL',
    webshopUsername: 'WooCommerce gebruikersnaam',
    yes: 'Ja'
  },
  configuration: {
    code: 'Code',
    fromDate: 'Geldig vanaf',
    value: 'Waarde'
  },
  editor: {
    entityFetchError: 'Dit item bestaat niet (meer) of u heeft geen toegang tot de gegevens van deze pagina.'
  },
  error: {
    general_request: 'Er is iets fout gegaan. Probeer het later opnieuw.',
    oneOrMoreInputs: 'De gegevens kunnen niet worden opgeslagen want het formulier bevat fouten.',
    request_401: 'U bent niet geautoriseerd',
    request_403: 'U heeft geen permissie tot deze actie',
    save: 'Er is iets fout gegaan. Probeer het later opnieuw.'
  },
  errorLog: {
    description:
      'Now the management system are basically the form of the spa, it enhances the user experience, but it also increases the possibility of page problems, a small negligence may lead to the entire page deadlock. Fortunately Vue provides a way to catch handling exceptions, where you can handle errors or report exceptions.',
    documentation: 'Document introduction',
    tips: 'Please click the bug icon in the upper right corner'
  },
  errorPages: {
    backToHome: 'Terug naar thuispagina',
    headline401: 'U heeft geen toegang tot deze pagina',
    headline403: 'U heeft geen toegang tot deze pagina',
    headline404: 'Pagina niet gevonden',
    message401: 'Indien u het hier niet mee eens bent, neem dan contact op met uw beheerder.',
    message403: 'Indien u het hier niet mee eens bent, neem dan contact op met uw beheerder.',
    message404: 'Controleer of de ingevoerde URL correct is.',
    return: 'Terug',
    title401: 'Oops',
    title403: 'Toegang geweigerd',
    title404: 'Sorry'
  },
  eventlog: {
    exception: 'Exception',
    level: 'Level',
    logger: 'Logger',
    message: 'Message',
    occurredAt: 'Datum tijd',
    thread: 'Thread'
  },
  fileUpload: {
    dragAndDropHtml: 'Sleep om te uploaden',
    uploadTip: 'Upload file'
  },
  forgotPassword: {
    description:
      'U kunt uw wachtwoord opnieuw instellen. Vul het onderstaande formulier in om de instructies per email te ontvangen.',
    done: 'Er is een email verstuurd naar {email}. Volg de instructies die hierin zijn vermeld.',
    submit: 'Verstuur email',
    title: 'Wachtwoord vergeten'
  },
  home: {
    welcomeVisitor: 'Welkom bezoeker'
  },
  language: {
    code: 'ISO 639-1 Code'
  },
  login: {
    code: 'Gebruikersnaam',
    forbidden: 'Gebruiker/IP combinatie is geblokkeerd.',
    logIn: 'Inloggen',
    password: 'Wachtwoord',
    title: 'Inloggen',
    unauthorised: 'Deze inloggegevens zijn onjuist.'
  },
  mail: {
    bcc: 'Bcc',
    body: 'Bericht',
    cc: 'Cc',
    error: 'Er is een probleem opgetreden. Uw email is niet verstuurd.',
    from: 'Van',
    subject: 'Onderwerp',
    success: 'Uw email is succesvol verzonden',
    to: 'Aan'
  },
  navbar: {
    dashboard: 'Dashboard',
    language: 'Taal',
    logOut: 'Uitloggen',
    screenfull: 'Screenfull',
    size: 'Global Size',
    theme: 'Theme'
  },
  newPassword: {
    description: 'U kunt nu uw wachtwoord permanent wijzigen.',
    done: 'Uw wachtwoord is gewijzigd.',
    invalidToken: 'Deze link is verlopen.',
    submit: 'Wijzig wachtwoord',
    title: 'Nieuw wachtwoord'
  },
  permission: {
    AccessLog: 'Access log',
    Ban: 'Ban',
    BulkOrder: 'Bulkbestellingen',
    Configuration: 'Instellingen',
    Country: 'Landen',
    Customer: 'Klanten',
    CustomerAddress: 'Klantadressen',
    DepositClass: 'Statiegeldklassen',
    DiscountRule: 'Kortingsregels',
    Domain: 'Domeinen',
    EventLog: 'Event log',
    ExternalApiUser: 'Externe API gebruikers',
    Language: 'Talen',
    Option: 'Optielijsten',
    Permission: 'Permissies',
    Product: 'Producten',
    ProductAttribute: 'Producteigenschappen',
    ProductAttributeItem: 'Producteigenschap items',
    ProductCategory: 'Productcategorieën',
    ProductRegistrationGroup: 'Omzetgroepen',
    Role: 'Rollen',
    Translation: 'Vertalingen',
    User: 'Gebruikers',
    execute: 'Uitvoeren',
    none: 'Geen',
    pageIsReadonly: 'U heeft geen rechten om de gegevens op deze pagina te wijzigen.',
    read: 'Lezen',
    roles: 'Uw rollen',
    selectPlaceholder: 'Kies het toegangsniveau',
    switchRoles: 'Rol wisselen',
    tabPermissions: 'Permissies',
    tabTaskTypes: 'Taaksoorten',
    write: 'Schrijven'
  },
  pickrec: {
    chooseEntryFor: 'Selecteer "{caption}"',
    itemNotFound: "'{caption}' niet gevonden",
    multiSearchFiltersLabel: 'Filters',
    suggestionsMayBeMore: 'Er zijn mogelijk meer zoekresulten',
    suggestionsRestCount: 'Er zijn nog {count} andere zoekresultaten'
  },
  product: {
    hasDeposit: 'Heeft statiegeld',
    hasVariants: 'Heeft varianten',
    image: 'Afbeelding',
    outOfStock: 'Uitverkocht',
    productinfo: 'Product info',
    variant: 'Variant van'
  },
  productCategory: {
    wooCommerceDisplayType: 'WooCommerce Weergave Type'
  },
  request: {
    networkError: 'Het netwerk is op dit moment niet bereikbaar.'
  },
  route: {
    Table: 'Table',
    avatarUpload: 'Avatar Upload',
    clipboardDemo: 'Clipboard',
    complexTable: 'Complex Table',
    componentIndex: 'Introduction',
    componentMixin: 'Mixin',
    components: 'Components',
    countTo: 'CountTo',
    createBulkOrder: 'Bulkbestelling aanmaken',
    createCountry: 'Land aanmaken',
    createCustomer: 'Klant aanmaken',
    createCustomerAddress: 'Klantadres aanmaken',
    createDepositClass: 'Statiegeldklasse aanmaken',
    createDiscountRule: 'Kortingsregel aanmaken',
    createDomain: 'Domein aanmaken',
    createExternalApiUser: 'Externe API login aanmaken',
    createLanguage: 'Taal aanmaken',
    createProduct: 'Product aanmaken',
    createProductAttribute: 'Product eigenschap aanmaken',
    createProductAttributeItem: 'Product eigenschap item aanmaken',
    createProductCategory: 'Productcategorie aanmaken',
    createProductRegistrationGroup: 'Omzetgroep aanmaken',
    createRole: 'Rol aanmaken',
    createUser: 'Gebruiker aanmaken',
    customTreeTable: 'Custom TreeTable',
    dashboard: 'Dashboard',
    directivePermission: 'Directive Permission',
    documentation: 'Documentation',
    dragDialog: 'Drag Dialog',
    dragTable: 'Drag Table',
    dropzone: 'Dropzone',
    dynamicTable: 'Dynamic Table',
    editBulkOrder: 'Bulkbestelling bewerken',
    editConfiguration: 'Instelling aanpassen',
    editCountry: 'Land bewerken',
    editCustomer: 'Klant bewerken',
    editCustomerAddress: 'Klantadres bewerken',
    editDepositClass: 'Statiegeldklasse bewerken',
    editDiscountRule: 'Kortingsregel bewerken',
    editDomain: 'Domein bewerken',
    editExternalApiUser: 'Externe API login bewerken',
    editLanguage: 'Taal bewerken',
    editProduct: 'Product bewerken',
    editProductAttribute: 'Product eigenschap bewerken',
    editProductAttributeItem: 'Product eigenschap item bewerken',
    editProductCategory: 'Productcategorie bewerken',
    editProductRegistrationGroup: 'Omzetgroep bewerken',
    editRole: 'Rol bewerken',
    editTranslation: 'Vertaling bewerken',
    editUser: 'Gebruiker bewerken',
    errorLog: 'Error Log',
    errorPages: 'Error Pages',
    example: 'Example',
    excel: 'Excel',
    exportExcel: 'Export Excel',
    exportZip: 'Export Zip',
    externalLink: 'External Link',
    form: 'Form',
    guide: 'Guide',
    home: 'Home',
    i18n: 'I18n',
    icons: 'Icons',
    inlineEditTable: 'Inline Edit',
    introduction: 'Introduction',
    jsonEditor: 'JSON Editor',
    markdown: 'Markdown',
    menu_admin: 'Beheer',
    menu_admin_accesslog: 'Access Log',
    menu_admin_application: 'Applicatie',
    menu_admin_configuration: 'Instellingen',
    menu_admin_countries: 'Landen',
    menu_admin_domains: 'Domeinen',
    menu_admin_eventlog: 'Event Log',
    menu_admin_external_api_users: 'Externe API logins',
    menu_admin_languages: 'Talen',
    menu_admin_manage_customers: 'Klanten',
    menu_admin_manage_products: 'Productbeheer',
    menu_admin_options: 'Keuzelijsten',
    menu_admin_roles: 'Rollen',
    menu_admin_security: 'Rollen en gebruikers',
    menu_admin_translations: 'Vertalingen',
    menu_admin_users: 'Gebruikers',
    menu_bulk_orders: 'Bulkbestellingen',
    menu_customer_addresses: 'Klantadressen',
    menu_customers: 'Klanten',
    menu_deposit_classes: 'Statiegeldklassen',
    menu_discount_rules: 'Kortingsregels',
    menu_product_attribute_items: 'Product eigenschap items',
    menu_product_attributes: 'Product eigenschappen',
    menu_product_categories: 'Productcategorieën',
    menu_product_registration_groups: 'Omzetgroepen',
    menu_products: 'Producten',
    page401: '401',
    page403: '403',
    page404: '404',
    pagePermission: 'Page Permission',
    permission: 'Permission',
    selectExcel: 'Export Selected',
    splitPane: 'SplitPane',
    sticky: 'Sticky',
    tab: 'Tab',
    theme: 'Theme',
    tinymce: 'Tinymce',
    treeTable: 'Tree Table',
    uploadExcel: 'Upload Excel',
    userSettings: 'Instellingen gebruiker',
    zip: 'Zip'
  },
  size: {
    medium: 'Normaal',
    mini: 'Kleinst',
    small: 'Klein'
  },
  stringInsert: {
    btnText: 'Invoegen...',
    closeOnInsert: 'Sluit dialoog na invoegen',
    textIntro: 'Klik op een tekst om deze in te voegen.',
    textIntroUnavailable: 'Er zijn geen teksten om in te voegen.',
    textTitle: 'Tekst invoegen',
    toastInsert: 'Ingevoegd: {name}'
  },
  table: {
    actions: 'Acties',
    searchPlaceholder: 'Typ om te zoeken',
    searchSelectPlaceholder: 'Selecteer om te zoeken'
  },
  tagsView: {
    close: 'Sluiten',
    closeAll: 'Alle sluiten',
    closeOthers: 'Andere sluiten',
    refresh: 'Verversen'
  },
  textArea: {
    placeholder: 'Uw tekst'
  },
  translation: {
    code: 'Vertalingcode'
  },
  user: {
    avatar: 'Profielafbeelding',
    avatarInfo: 'URL naar profielafbeelding',
    newPassword: 'Nieuw wachtwoord',
    newPasswordInfo: 'Laat leeg als u uw wachtwoord niet wilt wijzigen',
    oldPassword: 'Huidig wachtwoord',
    oldPasswordInfo: 'Voer ter controle uw huidige wachtwoord in',
    oldPasswordInvalid: 'U heeft niet het correcte huidige wachtwoord opgegeven',
    passwordChanged: 'Uw wachtwoord is aangepast',
    repeatPassword: 'Herhaal wachtwoord',
    repeatPasswordInfo: 'Herhaal ter controle uw nieuwe wachtwoord',
    roles: 'Rollen'
  },
  validation: {
    complexity: 'Kies complexiteit',
    ean: 'Deze barcode is incorrect',
    email: 'Dit is een ongeldig e-mailadres',
    error: {
      alphanumeric: 'Minstens 1 symbool is vereist',
      digit: 'Minstens 1 getal is vereist',
      isFromDatabase: 'Het wachtwoord kan niet worden gevalideerd, omdat de server niet bereikbaar is.',
      itemNotFound: 'Bestand is reeds verwijderd.',
      length: 'Minstens {count} karakters zijn vereist',
      lowercase: 'Minstens 1 kleine letter is vereist',
      passwordComplexity: 'Het wachtwoord voldoet niet aan de vereiste complexiteit',
      uppercase: 'Minstens 1 hoofdletter is vereist'
    },
    exceedMax: 'Deze waarde is te groot',
    formValidationFailed: 'Dit formulier bevat fouten. Controleer uw invoer en probeer het opnieuw',
    numeric: 'Alleen cijfers toegestaan',
    oldPasswordRequired: 'Vul het oude wachtwoord in',
    repeatPasswordMismatch: 'Twee wachtwoorden komen niet overeen',
    required: 'Dit veld is vereist',
    unique: 'Deze waarde is niet uniek',
    wordPressSlug: 'Dit veld mag alleen kleine letters, cijfers en een - (streepje) bevatten.'
  },
  wizard: {
    next: 'Volgende',
    prev: 'Terug',
    skip: 'Overslaan'
  }
};
