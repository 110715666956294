import Vue from 'vue';
import Vuex from 'vuex';
import app from './modules/app';
import errorLog from './modules/errorLog';
import tagsView from './modules/tagsView';
import authentication from './modules/authentication';
import getters from './getters';
import editor from './modules/editor';
import notify from './modules/notify';
import router from './modules/router';

import crudStoreModules from '@/crud-entities/store';

Vue.use(Vuex);

const store = ((window as any).store = new Vuex.Store({
  getters,
  modules: {
    app,
    authentication,
    errorLog,
    tagsView,
    editor,
    notify,
    router,
    ...crudStoreModules
  }
}));

export default store;
