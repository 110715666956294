<template>
  <action-dialog
    type="question"
    :callback="dialogCallback"
    :title="title"
    :before-submit="beforeSubmit"
    :message="message"
    :cancel-button-text="cancelButtonText"
    :confirm-button-text="confirmButtonText"
    :width="width"
    :close-on-click-modal="closeOnClickModal"
    :show-cancel="showCancel"
    :show-close="showClose"
    :close-on-press-escape="closeOnPressEscape"
  >
    <el-form ref="form" :rules="rules" :model="form" label-position="top">
      <el-form-item prop="date" :label="message">
        <date-picker v-model="form.date" :format="dateFormat" />
      </el-form-item>
    </el-form>
  </action-dialog>
</template>
<script>
import i18n from '@/i18n';
import DatePicker from '@/components/DSE/DatePicker';
import ActionDialog from '@/components/ActionDialog';
import { validatorRequire } from '@/utils/validators';
export default {
  components: {
    ActionDialog,
    DatePicker
  },
  data() {
    return {
      defaultValue: null,
      callback: null,
      type: undefined,
      title: undefined,
      message: undefined,
      cancelButtonText: undefined,
      confirmButtonText: undefined,
      dangerouslyUseHTMLString: false,
      width: undefined,
      closeOnClickModal: undefined,
      closeOnPressEscape: undefined,
      showCancel: undefined,
      showClose: undefined,
      i18n,
      dateFormat: undefined,
      form: {},
      rules: undefined
    };
  },
  mounted() {
    this.$set(this.form, 'date', this.defaultValue);
  },
  created() {
    this.rules = {
      date: [{ validator: validatorRequire }]
    };
  },
  methods: {
    async beforeSubmit() {
      const valid = await this.$refs.form.validate();
      if (!valid) {
        throw new Error('Form not valid');
      }
    },
    dialogCallback(action) {
      this.callback(action, this.form.date);
    }
  }
};
</script>
