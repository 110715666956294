import { toDateTimeString } from '@/utils/date';

function transformItem2Dto(item) {
  const dto = {
    discountRuleID: item.id,
    ...item,
    dateOnSaleFrom: toDateTimeString(item.dateOnSaleFrom),
    dateOnSaleTo: toDateTimeString(item.dateOnSaleTo)
  };
  delete dto.id;
  return dto;
}

export const apiOverrides = { transformItem2Dto };
