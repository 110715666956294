/*eslint no-unused-vars: "error"*/
import * as accesslogOptions from './options/accesslog';
import * as eventlogOptions from './options/eventlog';
import * as permissionOptions from './options/permission';
import * as optionOptions from './options/option';
import * as roleOptions from './options/role';
import * as userOptions from './options/user';
import * as productOptions from './options/product';
import * as discountRuleOptions from './options/discountrule';

// PLOP: Import option files
import * as productRegistrationGroupOptions from './options/productregistrationgroup';
// eslint-disable-next-line
import { RouteParent, CrudStore } from './interfaces';

const Security = {
  name: 'Security',
  srcPath: '@/pages/admin/security/',
  i18nTitlePrefix: 'menu_security_'
} as RouteParent;

const Application = {
  name: 'Application',
  srcPath: '@/pages/admin/application/',
  i18nTitlePrefix: 'menu_application_'
} as RouteParent;

const Company = {
  name: 'Company',
  srcPath: '@/pages/admin/company/',
  i18nTitlePrefix: 'menu_company_'
} as RouteParent;

const routeParentPages = {
  Security,
  Application,
  Company
};

export const routerPages = {};
Object.keys(routeParentPages).forEach(key => {
  routerPages[routeParentPages[key].name] = [];
});

const eventlog = {
  permissionName: 'Eventlog',
  requestUrl: '/eventlog',
  store: 'eventlog',
  backendEntityIDField: 'eventLogID',
  options: eventlogOptions
} as CrudStore;

const accesslog = {
  permissionName: 'Accesslog',
  requestUrl: '/accesslog',
  store: 'accesslog',
  backendEntityIDField: 'accessLogID',
  options: accesslogOptions
} as CrudStore;

const configuration = {
  permissionName: 'Configuration',
  requestUrl: '/configuration',
  store: 'configuration',
  backendEntityIDField: 'configurationID'
} as CrudStore;

const permission = {
  permissionName: 'Permission',
  requestUrl: '/permission',
  store: 'permission',
  backendEntityIDField: 'permissionID',
  options: permissionOptions
} as CrudStore;

const option = {
  permissionName: 'Option',
  requestUrl: '/option',
  store: 'option',
  backendEntityIDField: 'optionID',
  options: optionOptions
} as CrudStore;

const role = {
  permissionName: 'Role',
  requestUrl: '/role',
  store: 'role',
  backendEntityIDField: 'roleID',
  options: roleOptions
} as CrudStore;

const user = {
  permissionName: 'User',
  requestUrl: '/user',
  store: 'user',
  backendEntityIDField: 'userID',
  options: userOptions,
  route: {
    parent: Security,
    listViewName: 'Users'
  }
} as CrudStore;

const language = {
  permissionName: 'Language',
  requestUrl: '/language',
  store: 'language',
  backendEntityIDField: 'languageID'
} as CrudStore;

const country = {
  permissionName: 'Country',
  requestUrl: '/country',
  store: 'country',
  backendEntityIDField: 'countryID'
} as CrudStore;

const translation = {
  permissionName: 'Translation',
  requestUrl: '/string-description',
  store: 'translation',
  backendEntityIDField: 'stringDescriptionID'
} as CrudStore;

// PLOP: Crud store modules
const bulkOrder = {
  permissionName: 'BulkOrder',
  requestUrl: '/bulk-order',
  store: 'bulkOrder',
  backendEntityIDField: 'bulkOrderID'
} as CrudStore;

const customerAddress = {
  permissionName: 'CustomerAddress',
  requestUrl: '/customer-address',
  store: 'customerAddress',
  backendEntityIDField: 'customerAddressID'
} as CrudStore;

const customer = {
  permissionName: 'Customer',
  requestUrl: '/customer',
  store: 'customer',
  backendEntityIDField: 'customerID'
} as CrudStore;

const depositclass = {
  permissionName: 'DepositClass',
  requestUrl: '/deposit-class',
  store: 'depositClass',
  backendEntityIDField: 'depositClassID'
} as CrudStore;

const discountRule = {
  permissionName: 'DiscountRule',
  requestUrl: '/discount-rule',
  store: 'discountRule',
  backendEntityIDField: 'discountRuleID',
  options: discountRuleOptions
} as CrudStore;

const productCategory = {
  permissionName: 'ProductCategory',
  requestUrl: '/product-category',
  store: 'productCategory',
  backendEntityIDField: 'productCategoryID'
} as CrudStore;

const productRegistrationGroup = {
  permissionName: 'ProductRegistrationGroup',
  requestUrl: 'product-registration-group',
  store: 'productRegistrationGroup',
  backendEntityIDField: 'productRegistrationGroupID',
  options: productRegistrationGroupOptions
} as CrudStore;

const product = {
  permissionName: 'Product',
  requestUrl: '/product',
  store: 'product',
  backendEntityIDField: 'productID',
  options: productOptions
} as CrudStore;

const externalApiUser = {
  permissionName: 'ExternalApiUser',
  requestUrl: '/externalApiUser',
  store: 'externalApiUser',
  backendEntityIDField: 'externalApiUserID'
} as CrudStore;

const domain = {
  permissionName: 'Domain',
  requestUrl: '/domain',
  store: 'domain',
  backendEntityIDField: 'domainID'
} as CrudStore;

const productAttribute = {
  permissionName: 'ProductAttribute',
  requestUrl: '/product-attribute',
  store: 'productAttribute',
  backendEntityIDField: 'productAttributeID'
} as CrudStore;

const productAttributeItem = {
  permissionName: 'ProductAttributeItem',
  requestUrl: '/product-attribute-item',
  store: 'productAttributeItem',
  backendEntityIDField: 'productAttributeItemID'
} as CrudStore;

const entities = [
  bulkOrder,
  customerAddress,
  customer,
  depositclass,
  discountRule,
  productAttribute,
  productAttributeItem,
  productCategory,
  productRegistrationGroup,
  product,
  eventlog,
  externalApiUser,
  domain,
  accesslog,
  configuration,
  language,
  permission,
  option,
  role,
  user,
  country,
  translation
];

export default entities;
