function transformItem2Dto(item) {
  const roleTaskTypes =
    item.roleTaskTypes &&
    item.roleTaskTypes.map(permission => {
      const value = { ...permission, taskTypeID: permission.id };
      delete value.id;
      return value;
    });
  const dto = {
    description: item.description,
    domainID: item.domainID,
    name: item.name,
    roleID: item.id,
    rolePermissions: item.rolePermissions || [],
    roleTaskTypes: roleTaskTypes || []
  };
  return dto;
}

function transformDto2Item(dto) {
  const roleTaskTypes =
    dto.roleTaskTypes &&
    dto.roleTaskTypes.map(permission => {
      const roleTaskType = {
        readYN: permission.readYN,
        createYN: permission.createYN,
        executeYN: permission.executeYN,
        assignYN: permission.assignYN,
        id: permission.taskTypeID
      };
      return roleTaskType;
    });
  const item = {
    description: dto.description,
    domainID: dto.domainID,
    id: dto.roleID,
    name: dto.name,
    rolePermissions: dto.rolePermissions || [],
    roleTaskTypes: roleTaskTypes || []
  };
  return item;
}

export const apiOverrides = { transformItem2Dto, transformDto2Item };

export const storeExtensions = {
  actions: {}
};
