import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import elementUI from 'element-ui';

import './errorLog'; // error log
import './permission'; // permission control

import './registerServiceWorker';
// import CSS
import 'normalize.css/normalize.css';
import '@/styles/theme-project/index.css';
import '@/styles/index.scss';

/*  prevents de message "You are running Vue in development mode. Make sure to turn on production mode when deploying
    for production. See more tips at https://vuejs.org/guide/deployment.html" from popping up in the console */
Vue.config.productionTip = false;

Vue.use(elementUI, {
  i18n: (key, value) => i18n.t(key, value), // @fixme: store.state should not be of type any
  size: (store.state as any).app.size
});

new Vue({
  i18n,
  render: createVNode => createVNode(App),
  router,
  store // h is a shorthand for createElement
}).$mount('#app');
