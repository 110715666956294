import { toISOString } from '@/utils/date';

function transformItem2Dto(item) {
  const dto = {
    eventLogID: item.id,
    ...item,
    fromDate: toISOString(item.fromDate)
  };
  delete dto.id;
  return dto;
}

export const apiOverrides = { transformItem2Dto };

export const storeExtensions = {
  actions: {
    update({ commit }, item) {
      throw new Error('Event logs are not updatable');
    },
    create({ commit }, item) {
      throw new Error('Event logs are not creatable');
    }
  }
};
