// Keys ougt to be sorted alphabetically, case sensitive.
// To autosort: repeat CTRL+S until all keys are sorted
//  OR
// repeat command until sorted: npm run lint-fix

/* eslint sort-keys-fix/sort-keys-fix: error */
export default {
  accesslog: {
    accessedAt: 'Accessed at',
    accessedBy: 'Accessed by',
    accessedResource: 'Accessed resource',
    ip: 'IP',
    parameters: 'Parameters'
  },
  autoUpdate: {
    alertConfirm: 'Reload page',
    alertMessage: 'There is a new update available for this application. Please reload the page to update.',
    alertTitle: 'Update'
  },
  common: {
    add: 'Add',
    address: 'Address',
    addressLineOne: 'Address line one',
    addressLineTwo: 'Address line two',
    autoLoginUrl: 'Login link',
    billingAddress: 'Billing address',
    block_notification_not_saved_warning: 'Notification setting not saved. Please try again later.',
    browserNotSupported: 'Your browser does not support this feature',
    bulkOrders: 'Bulkorders',
    cancel: 'Cancel',
    chamberOfCommerceNumber: 'Chamber of Commerce number',
    city: 'City',
    clearFilters: 'Clear all filters',
    close: 'Close window',
    close_unsaved_warning: 'You have unsaved changes. Are you sure you want to close this window?',
    close_warning: 'Are you sure you want to close this screen?',
    code: 'Code',
    companyAddress: 'Company address',
    companyName: 'Company name',
    confirm: 'Confirm',
    copy: 'Copy',
    copyToClipboard: 'Copy to clipboard',
    copyToClipboardFail: 'Copy to clipboard failed',
    copyToClipboardSuccess: 'Copied to clipboard',
    country: 'Country',
    coupledDepositProductNameOnWooCommerce: 'Name of deposit-product on WooCommerce',
    customer: 'Customer',
    customerName: 'Customer name',
    data_created: 'The data has been saved',
    data_save_error: 'The data could not be be saved',
    data_updated: 'The changes are saved',
    date: 'Date',
    dateOnSaleFrom: 'Start sale',
    dateOnSaleTo: 'End sale',
    decimals: 'Decimals',
    defaultProductCategory: 'Default product category during import',
    delete: 'Delete',
    delete_cancelled: 'Delete cancelled',
    delete_completed: 'Delete completed',
    delete_record_summary_warning: 'Are you sure you want to delete <b>{summary}</b>?',
    delete_record_warning: 'Are you sure you want to delete this?',
    department: 'Department',
    deposit: 'Deposit',
    depositClass: 'Deposit class',
    description: 'Description',
    discountRules: 'Discount rules',
    displayName: 'Display name',
    domain: 'Domain',
    domainDescription: 'Description',
    domainName: 'Domain name',
    dontSave: "Don't save",
    dontSend: "Don't send",
    dont_show_again_warning: 'Never ask again',
    draft: 'Draft',
    ean: 'EAN-13',
    edit: 'Edit',
    email: 'Email address',
    emptyString: ' ',
    error: 'Error',
    export: 'Export',
    file: 'File',
    firstName: 'First name',
    fullDescription: 'Full description',
    gender: 'Gender',
    general: 'General',
    id: 'ID',
    image: 'Image',
    images: 'Images',
    inStockExpectedDateTime: 'Date expected in-stock',
    inactive: 'Inactive',
    initials: 'Initials',
    itemNumber: 'Item number',
    jobFunction: 'Job function',
    language: 'Language',
    lastName: 'Last name',
    leave_mail_warning: 'You are about to leave this page. Do you want to send the email before leaving?',
    leave_unsaved_warning: 'You are about to leave this page. Do you want to save changes',
    loading: 'Loading ...',
    mailingAddress: 'Mailing address',
    modified: 'Modified',
    name: 'Name',
    no: 'No',
    noData: 'No data',
    notApplicable: 'Not applicable',
    ok: 'OK',
    overrideAddressOnWooCommerce: 'Update address on WooCommerce, ignore address changes on WooCommerce (once)',
    parent: 'Parent',
    parentName: 'Parent',
    password: 'Password',
    phoneNumber: 'Phone',
    postCode: 'Postal code',
    postalCode: 'Postal code',
    prefix: 'Prefix',
    product: 'Product',
    productAttribute: 'Product attribute',
    productAttributeItem: 'Product attribute item',
    productAttributeItems: 'Product attribute items',
    productCategory: 'Product category',
    productCategoryName: 'Product category',
    productRegistrationGroup: 'Revenue-group',
    productRegistrationGroupName: 'Revenue-group',
    protectedCategoryID: 'ID protected category',
    publish: 'Publish',
    publishToWebshopYN: 'Publish to webshop',
    quantity: 'Quantity',
    question: 'Question',
    rawPrice: 'Raw price',
    readMore: 'Read more ...',
    reference: 'Reference',
    remark: 'Remark',
    remarks: 'Remarks',
    salePrice: 'Sale price',
    save: 'Save',
    saveClose: 'Save & close',
    saveNext: 'Save & next',
    search: 'Search',
    send: 'Send',
    shippingAddress: 'Shipping address',
    shortDescription: 'Short description',
    skip: 'Skip',
    snelStartGroupID: 'SnelStart ID',
    snelStartID: 'SnelStart ID',
    snelStartKlantCode: 'Customer ref',
    status: 'Status',
    stay: 'Stay on page',
    streetName: 'Street',
    streetNumber: 'Number',
    success: 'Success',
    switchOff: 'Off',
    switchOn: 'On',
    syncError: 'Error',
    taskstatus: 'Task status',
    title: 'Title',
    topCategoryOnWooCommerceID: 'ID TopCategory on WooCommerce',
    topCategoryOnWooCommerceName: 'Name TopCategory on WooCommerce',
    translations: 'Translations',
    type: 'Type',
    unit: 'Unit',
    userName: 'User name',
    username: 'Username',
    vatNumber: 'VAT number',
    vatPercentage: 'MwSt prozentsatz',
    warning: 'Warning',
    webshopURL: 'WooCommerce URL',
    webshopUsername: 'WooCommerce Nutzername',
    yes: 'Yes'
  },
  configuration: {
    code: 'Code',
    fromDate: 'Valid from',
    value: 'Value'
  },
  editor: {
    entityFetchError: 'This item does not exist (anymore) or you do not have the permission to view this page.'
  },
  error: {
    general_request: 'Something went wrong. Try again later.',
    oneOrMoreInputs: 'Data could not be saved because there are errors in the form',
    request_401: 'You are not authorised',
    request_403: 'You do not have permission to perform this action',
    save: 'Something went wrong. Try again later.'
  },
  errorLog: {
    description:
      'Now the management system are basically the form of the spa, it enhances the user experience, but it also increases the possibility of page problems, a small negligence may lead to the entire page deadlock. Fortunately Vue provides a way to catch handling exceptions, where you can handle errors or report exceptions.',
    documentation: 'Document introduction',
    tips: 'Please click the bug icon in the upper right corner'
  },
  errorPages: {
    backToHome: 'Back to home',
    headline401: "You don't have permission to go to this page",
    headline403: "You don't have permission to go to this page",
    headline404: 'Page not found',
    message401: 'If you do not agree with this, please contact your administrator.',
    message403: 'If you do not agree with this, please contact your administrator.',
    message404: 'Please check that the URL you entered is correct.',
    return: 'return',
    title401: 'Oops',
    title403: 'Access denied',
    title404: 'Sorry'
  },
  eventlog: {
    exception: 'Exception',
    level: 'Level',
    logger: 'Logger',
    message: 'Message',
    occurredAt: 'Occurred at',
    thread: 'Thread'
  },
  fileUpload: {
    dragAndDropHtml: 'Drag and drop to upload',
    uploadTip: 'Upload file'
  },
  forgotPassword: {
    description:
      'You can change your password for security reasons or reset it if you forget it. Complete the form below to get the instructions sent to your email',
    done: 'An email has been sent to {email}. Please check your email for instructions.',
    submit: 'Send email',
    title: 'Forgot password'
  },
  home: {
    welcomeVisitor: 'Welcome visitor'
  },
  language: {
    code: 'ISO 639-1 Code'
  },
  login: {
    code: 'User name',
    forbidden: 'User/IP combination has been blocked.',
    logIn: 'Log in',
    password: 'Password',
    title: 'Log in',
    unauthorised: 'Login information is wrong',
    username: 'Username'
  },
  mail: {
    bcc: 'Bcc',
    body: 'Body',
    cc: 'Cc',
    error: 'An error has occured. Your e-mail was not sent.',
    from: 'From',
    subject: 'Subject',
    success: 'Your e-mail has been sent successfully.',
    to: 'To'
  },
  navbar: {
    dashboard: 'Dashboard',
    language: 'Language',
    logOut: 'Log Out',
    screenfull: 'Screenfull',
    size: 'Global Size',
    theme: 'Theme'
  },
  newPassword: {
    description: 'You can now permanently change your password.',
    done: 'Your password has successfully been changed.',
    invalidToken: 'This link has expired.',
    submit: 'Change password',
    title: 'New password'
  },
  permission: {
    AccessLog: 'Access log',
    Ban: 'Ban',
    BulkOrder: 'Bulk orders',
    Configuration: 'Settings',
    Country: 'Countries',
    Customer: 'Customers',
    CustomerAddress: 'Customeraddresses',
    DepositClass: 'Deposit classes',
    DiscountRule: 'Discount rules',
    Domain: 'Domains',
    EventLog: 'Event log',
    ExternalApiUser: 'External API',
    Language: 'Languages',
    Option: 'Options',
    Permission: 'Permissions',
    Product: 'Products',
    ProductAttribute: 'Productattributes',
    ProductAttributeItem: 'Productattribute items',
    ProductCategory: 'Productcategories',
    ProductRegistrationGroup: 'Revenue-groups',
    Role: 'Roles',
    Translation: 'Translations',
    User: 'Users',
    execute: 'Execute',
    none: 'None',
    pageIsReadonly: 'You are not allowed to edit this page.',
    read: 'Read',
    roles: 'Your roles',
    selectPlaceholder: 'Select the access level',
    switchRoles: 'Switch roles',
    tabPermissions: 'Permissions',
    tabTaskTypes: 'Task Types',
    write: 'Write'
  },
  pickrec: {
    chooseEntryFor: 'Choose "{caption}"',
    itemNotFound: "'{caption}' not found",
    multiSearchFiltersLabel: 'Filters',
    suggestionsMayBeMore: 'There are possibly more search hits',
    suggestionsRestCount: 'There are {count} more search hits'
  },
  product: {
    hasDeposit: 'Has deposit',
    hasVariants: 'Has variants',
    image: 'Image',
    outOfStock: 'Out of stock',
    productinfo: 'Product Info',
    variant: 'Variant of'
  },
  productCategory: {
    wooCommerceDisplayType: 'WooCommerce Display Type'
  },
  request: {
    networkError: 'Network can not be reached.'
  },
  route: {
    Table: 'Table',
    avatarUpload: 'Avatar Upload',
    clipboardDemo: 'Clipboard',
    complexTable: 'Complex Table',
    componentIndex: 'Introduction',
    componentMixin: 'Mixin',
    components: 'Components',
    countTo: 'CountTo',
    createBulkOrder: 'Create a bulkorder',
    createCountry: 'Create country',
    createCustomer: 'Create a customer',
    createCustomerAddress: 'Create a customeraddress',
    createDepositClass: 'Create a deposit class',
    createDiscountRule: 'Create a discount rule',
    createDomain: 'Create domain',
    createExternalApiUser: 'Create External API login',
    createLanguage: 'Create language',
    createProduct: 'Create a product',
    createProductAttribute: 'Create a product attribute',
    createProductAttributeItem: 'Create a product attribute item',
    createProductCategory: 'Create a productcategory',
    createProductRegistrationGroup: 'Create revenue group',
    createRole: 'Create a role',
    createUser: 'Create a user',
    customTreeTable: 'Custom TreeTable',
    dashboard: 'Dashboard',
    directivePermission: 'Directive Permission',
    documentation: 'Documentation',
    dragDialog: 'Drag Dialog',
    dragTable: 'Drag Table',
    dropzone: 'Dropzone',
    dynamicTable: 'Dynamic Table',
    editBulkOrder: 'Edit bulkorder',
    editConfiguration: 'Edit setting',
    editCountry: 'Edit country',
    editCustomer: 'Edit customer',
    editCustomerAddress: 'Edit customeraddress',
    editDepositClass: 'Edit deposit class',
    editDiscountRule: 'Edit discount rule',
    editDomain: 'Edit domain',
    editExternalApiUser: 'Edit external API user',
    editLanguage: 'Edit language',
    editProduct: 'Edit product',
    editProductAttribute: 'Edit product attribute',
    editProductAttributeItem: 'Edit product attribute item',
    editProductCategory: 'Edit productcategory',
    editProductRegistrationGroup: 'Edit revenue group',
    editRole: 'Edit role',
    editTranslation: 'Edit translation',
    editUser: 'Edit user',
    errorLog: 'Error Log',
    errorPages: 'Error Pages',
    example: 'Example',
    excel: 'Excel',
    exportExcel: 'Export Excel',
    exportZip: 'Export Zip',
    externalLink: 'External Link',
    form: 'Form',
    guide: 'Guide',
    home: 'Home',
    i18n: 'I18n',
    icons: 'Icons',
    inlineEditTable: 'Inline Edit',
    introduction: 'Introduction',
    jsonEditor: 'JSON Editor',
    markdown: 'Markdown',
    menu_admin: 'Beheer',
    menu_admin_accesslog: 'Access Log',
    menu_admin_application: 'Application',
    menu_admin_configuration: 'Settings',
    menu_admin_countries: 'Countries',
    menu_admin_domains: 'Domains',
    menu_admin_eventlog: 'Event Log',
    menu_admin_external_api_users: 'External API logins',
    menu_admin_languages: 'Languages',
    menu_admin_manage_customers: 'Customers',
    menu_admin_manage_products: 'Product Management',
    menu_admin_options: 'Options lists',
    menu_admin_roles: 'Roles',
    menu_admin_security: 'Roles and users',
    menu_admin_translations: 'Translation',
    menu_admin_users: 'Users',
    menu_bulk_orders: 'Bulk Orders',
    menu_customer_addresses: 'Customeraddresses',
    menu_customers: 'Customers',
    menu_deposit_classes: 'Deposit Classes',
    menu_discount_rules: 'Discount Rules',
    menu_product_attribute_items: 'Product attribute items',
    menu_product_attributes: 'Product attributes',
    menu_product_categories: 'Productcategories',
    menu_product_registration_groups: 'Revenue groups',
    menu_products: 'Products',
    page401: '401',
    page403: '403',
    page404: '404',
    pagePermission: 'Page Permission',
    permission: 'Permission',
    selectExcel: 'Export Selected',
    splitPane: 'SplitPane',
    sticky: 'Sticky',
    tab: 'Tab',
    theme: 'Theme',
    tinymce: 'Tinymce',
    treeTable: 'Tree Table',
    uploadExcel: 'Upload Excel',
    userSettings: 'User settings',
    zip: 'Zip'
  },
  size: {
    medium: 'Medium',
    mini: 'Mini',
    small: 'Small'
  },
  stringInsert: {
    btnText: 'Insert...',
    closeOnInsert: 'Sluit dialoog na invoegen',
    textIntro: 'Click on a text to insert.',
    textIntroUnavailable: 'There is nothing to insert.',
    textTitle: 'Insert text',
    toastInsert: 'Inserted: {name}'
  },
  table: {
    actions: 'Actions',
    searchPlaceholder: 'Type to search',
    searchSelectPlaceholder: 'Select to search'
  },
  tagsView: {
    close: 'Close',
    closeAll: 'Close All',
    closeOthers: 'Close Others',
    refresh: 'Refresh'
  },
  textArea: {
    placeholder: 'Your text'
  },
  translation: {
    code: 'Translation code'
  },
  user: {
    avatar: 'Profile picture',
    avatarInfo: 'URL to profile picture',
    newPassword: 'New password',
    newPasswordInfo: "Leave empty when you don't want to change your password",
    oldPassword: 'Current password',
    oldPasswordInfo: 'Enter your current password for verification',
    oldPasswordInvalid: 'You did not enter the correct current password',
    passwordChanged: 'Your password has been changed',
    repeatPassword: 'Repeat password',
    repeatPasswordInfo: 'Repeat your new password for verification',
    roles: 'Roles'
  },
  validation: {
    complexity: 'Choose complexity',
    ean: 'This barcode is incorrect',
    email: 'This is an invalid e-mail address',
    error: {
      alphanumeric: 'Password must contain a symbol',
      digit: 'Password must contain a digit',
      isFromDatabase: 'Could not validate your password, because the server could not be reached.',
      itemNotFound: 'Item not found.',
      length: 'Password must contain at least {count} characters',
      lowercase: 'Password must contain a lowercase character',
      passwordComplexity: 'The password does not meet the required complexity',
      uppercase: 'Password must contain an uppercase character'
    },
    exceedMax: 'This value is too high',
    formValidationFailed: 'This form has errors. Please review your input and retry',
    numeric: 'Only numbers allowed',
    oldPasswordRequired: 'Please input the old password',
    repeatPasswordMismatch: "Two inputs don't match",
    required: 'This field is required',
    unique: 'This value must be unique',
    wordPressSlug: 'This field can only contain small characters, number and a - (dash).'
  },
  wizard: {
    next: 'Next',
    prev: 'Back',
    skip: 'Skip'
  }
};
